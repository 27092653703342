@keyframes btn-loading {
    from {
        background-position: -4em 0;
    }
    to {
        background-position: -1.7em 0;
    }
}

.btn-loading {
    // background-color: #1dd1a1;
    &::before {
        content: "";
        // border-radius: $radius;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: repeating-linear-gradient(
            -60deg,
            rgba(white, 0.1),
            rgba(white, 0.1) 1em,
            rgba(black, 0.05) 1em,
            rgba(black, 0.05) 2em /* determines size */
        );
        background-size: 200%;
        animation: btn-loading 1s linear infinite;
    }
}

.bg-opacity-25 {
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 0.375rem !important;
}

::-webkit-scrollbar {
    height: 0.375rem !important;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(100, 116, 139, 0.6) !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.25rem !important;
}

::-webkit-scrollbar-track {
    background-color: rgba(100, 116, 139, 0.16) !important;
}

::-webkit-scrollbar-track {
    border-radius: 0.25rem !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
